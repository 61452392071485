/* eslint-disable unicorn/prefer-dom-node-dataset */
const CONFIRMATION_SELECTOR = '[data-confirmation-required]'
const NEXT_SELECTOR = '[data-confirmation-required-next]'
const DISABLED_CLASS_NAME = 'disabled'

/** 複数の確認必須な要素をクリックしないと次に進めないリンクへの利用を想定している */
export class ConfirmationRequired extends HTMLElement {
  connectedCallback(): void {
    requestAnimationFrame(() => this.init())
    this.addEventListener('click', this.onClick)
  }

  disconnectedCallback(): void {
    this.removeEventListener('click', this.onClick)
  }

  get nextElement(): HTMLElement | undefined {
    return this.querySelector<HTMLElement>(NEXT_SELECTOR) || undefined
  }

  private init() {
    this.nextElement?.classList.add(DISABLED_CLASS_NAME)
  }

  private onClick = (event: Event) => {
    if (!(event.target instanceof HTMLElement)) return

    const currentTarget = event.target.closest<HTMLElement>(CONFIRMATION_SELECTOR)
    if (!currentTarget) return

    currentTarget.removeAttribute('data-confirmation-required')
    currentTarget.classList.remove('btn-outline-danger')
    currentTarget.classList.add('btn-outline-secondary')

    const isConfirmed = this.querySelectorAll(CONFIRMATION_SELECTOR).length === 0
    if (!isConfirmed) return

    this.nextElement?.classList.remove(DISABLED_CLASS_NAME)
  }
}

declare global {
  interface Window {
    ConfirmationRequired: typeof ConfirmationRequired
  }
}

if (!window.customElements.get('confirmation-required')) {
  window.ConfirmationRequired = ConfirmationRequired
  window.customElements.define('confirmation-required', ConfirmationRequired)
}
