/* eslint-disable unicorn/no-await-expression-member */
import { asyncRewrap, rewrap } from '@sonicgarden/rewrap'
import { InputImage } from '@/src/application/components/InputImage/InputImage'

export const initComponents = (): void => {
  // NOTE: 動的インポートを使うとscroll snap利用箇所の初期スクロール位置が変わってしまうため
  rewrap('x-input-image', InputImage)

  asyncRewrap('x-multi-select', async () => (await import('@/src/shared/components/MultiSelect')).MultiSelect)
  asyncRewrap('x-talk-container', async () => (await import('@/src/application/components/Talk')).ApolloTalkContainer)
  asyncRewrap(
    'x-pwa-install-modal',
    async () =>
      (await import('@/src/application/components/pwa_install_modal/pwaInstallModalContainer'))
        .PwaInstallModalContainer,
  )
  asyncRewrap(
    'x-user-select',
    async () => (await import('@/src/application/components/user_select/userSelect')).UserSelect,
  )
  asyncRewrap(
    'x-textarea-with-hashtag',
    async () =>
      (await import('@/src/application/components/textarea_with_hashtag/TextareaWithHashtag')).TextareaWithHashtag,
  )
  asyncRewrap(
    'x-zengin-select-fields',
    async () => (await import('@/src/application/components/zengin')).ZenginSelectFields,
  )
  asyncRewrap('x-zengin-bank-name', async () => (await import('@/src/application/components/zengin')).ZenginBankName)
  asyncRewrap(
    'x-zengin-branch-name',
    async () => (await import('@/src/application/components/zengin')).ZenginBranchName,
  )
  asyncRewrap('x-custom-file', async () => (await import('@/src/shared/components/custom_file/customFile')).CustomFile)
  asyncRewrap('x-carousel', async () => (await import('@/src/application/components/Carousel/Carousel')).Carousel)
}
