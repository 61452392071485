import 'webcomponent-qr-code'
import initAppInstallBanners from './appInstallBanners'
import { setBadgin } from './badgin'
import { initComponents } from './components'
import { startExpo } from './expo'
import { initOnesignal } from './onesignal'
import registerServiceWorker from './registerServiceWorker'
import initWebpush from './webpush'
import { setupYoutubeEnded } from './youtubeEnded'
import '@/src/customElements/LuminousLightbox'

registerServiceWorker()
initAppInstallBanners()
initOnesignal()
initComponents()
initWebpush()
startExpo()

document.addEventListener('DOMContentLoaded', () => {
  setupYoutubeEnded()
  setBadgin()
})
