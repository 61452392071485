import 'element-internals-polyfill' // NOTE: @sonicgarden/native-datepicker-elementをsafari@16.4未満でも使えるようにするため
import 'iconify-icon'
import '@github/relative-time-element'
import { ImageCompression } from '@sonicgarden/image-compression-element'
import { start } from '@sonicgarden/rails-ujs-compat'
import 'bootstrap'
import '@/src/application/lib'
import '@/src/shared/pages/base'
import '@/src/customElements/AutoAnimate'
import '@/src/customElements/ConfirmationRequired'
import '@/src/customElements/CopyButton'
import '@/src/customElements/PdfPrintButton'
import '@sonicgarden/native-datepicker-element'
import '@sonicgarden/autosize-textarea-element'
import '@sonicgarden/bs-hovercard-element'
import '@sonicgarden/auto-submit-form-element'
import '@sonicgarden/bs-tooltip-element'
import { startTurbo } from '@/src/lib/turbo'

start()
startTurbo()

ImageCompression.defaultOptions = {
  initialQuality: 0.9,
  maxSizeMB: 2,
  maxWidthOrHeight: 2048,
}
