import type { ChangeEventHandler, FC } from 'react'
import { useCallback, useRef, useState } from 'react'
import { directUpload } from '@/src/lib'
import { compressImageIfSupported } from '@/src/lib/imageCompression'

const DEFAULT_SIZE = '120px'

type Blob = {
  src: string
  signedId: string
}

export type InputImageProps = {
  name: string
  size?: string
  blob?: Blob
}

const readFile = (file: File): Promise<string | undefined> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    const onLoad = () => {
      reader.removeEventListener('load', onLoad)
      const result = typeof reader.result === 'string' ? reader.result : undefined
      resolve(result)
    }
    reader.addEventListener('load', onLoad)
    reader.readAsDataURL(file)
  })
}

export const InputImage: FC<InputImageProps> = ({ name, blob, size = DEFAULT_SIZE }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [src, setSrc] = useState<string | undefined>(blob?.src)
  const [signedId, setSignedId] = useState<string | undefined>(blob?.signedId)

  const reset = useCallback(() => {
    setSrc(undefined)

    setSignedId(undefined)

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }, [])
  const handleClick = useCallback(() => {
    if (signedId) {
      if (confirm('削除します。本当によろしいですか？')) {
        reset()
      }
      return
    }

    inputRef.current?.click()
  }, [reset, signedId])

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(async (event) => {
    const files = event.target.files
    if (!files || files.length === 0) {
      return
    }

    const compressedFile = await compressImageIfSupported(files[0], {
      maxWidthOrHeight: 2048,
      maxSizeMB: 2,
      initialQuality: 0.9,
    })

    setSrc(await readFile(compressedFile))
    setSignedId(await directUpload(compressedFile))
  }, [])

  return (
    <div>
      <div
        className="bg-light d-flex align-items-center justify-content-center"
        style={{ width: size, height: size }}
        role="button"
        onClick={handleClick}
        tabIndex={-1}
        data-test-selector="input-image"
      >
        {src ? (
          <img src={src} alt="" className="mw-100 mh-100" />
        ) : (
          <span className="tw-iconify tw-bi--camera-fill text-body-secondary tw-text-3xl" />
        )}
      </div>
      <input type="file" accept="image/jpeg,image/png,image/gif" hidden ref={inputRef} onChange={handleChange} />
      {signedId && <input key={signedId} type="hidden" name={name} value={signedId} />}
    </div>
  )
}
